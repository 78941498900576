<template>
  <div id="app" class="join">
    <Nav />
    <div class="mt-50">
      <img class="top-img" :src="require(`@/assets/banner/banner-03${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="" />
      <!-- 乐团简介 -->
      <div class="artTraining-title flex center mt-25">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">{{$t('join.name')}}</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/join/join-6.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="join-hz">
        <p class="join-hz-p1 color1 f-12" v-html="$t('introductory.title1')">
         
        </p>
        <!-- <p class="join-hz-p1 color1 f-12 mt-15">
          {{ $t("join.introduction2") }}
        </p> -->
      </div>
      <div class="join-img">
        <!-- <img class="join-img1" src="@/assets/join/join-3.png" alt="" /> -->
         <video
          class="join-img1"
          controls
          src="https://c.vod.suzhousco.com/sv/52261218-17e47235ee6/52261218-17e47235ee6.mp4"
        >
          your browser does not support the video tag
        </video>
        <div class="join-img-hz"></div>
      </div>
      <!-- 乐团职能 -->
      <div class="artTraining-title flex center mt-35">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">{{$t('join.orchestra')}}</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/join/join-7.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="join-hz">
        <p class="join-hz-p1 color1 f-12">
          {{ $t("join.introduction3") }}
        </p>
      </div>
      <!-- 招聘信息 -->
      <div class="artTraining-title flex center mt-25">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">{{$t('join.recruitment')}}</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/join/join-8.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="join-hz2 mt-15">
        <img class="join-img3" src="@/assets/join/join-4.png" alt="" />
        <img class="join-img4" :src="require(`@/assets/join/join-5${$i18n.locale == 'zh'?'':'E'}.png`)" alt="" />
        <!-- <div class="join-recruitment"> -->
        <!-- <div class="join-recruitment-hz"></div> -->
        <!-- </div> -->
      </div>
      <!-- 考试信息 -->
      <div class="artTraining-title flex center mt-35 mb-15">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">{{$t('join.examInformation')}}</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/join/join-9.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="join-hz">
        <!-- 考试内容 -->
        <div class="join-information">
          <p class="join-information-p1">{{ $t("join.ei1") }}</p>
          <p class="join-information-p2">{{ $t("join.ei2") }}</p>
          <p class="join-information-p3">
            {{ $t("join.ei3") }}
          </p>
          <p class="join-information-p2">{{ $t("join.ei4") }}</p>
          <p class="join-information-p3">
            {{ $t("join.ei5") }}
          </p>
          <p class="join-information-p3">
            {{ $t("join.ei6") }}
          </p>
          <p class="join-information-p3">{{ $t("join.ei7") }}</p>
          <p class="join-information-p3">
            {{ $t("join.ei8") }}
          </p>
        </div>
        <!-- 报名 -->
        <div class="join-information">
          <p class="join-information-p1">{{ $t("join.rg1") }}</p>
          <!-- <p class="join-information-p2">{{ $t("join.rg2") }}</p>
          <p class="join-information-p3">{{ $t("join.rg3") }}</p>
          <p class="join-information-p2">{{ $t("join.rg4") }}</p> -->
          <p class="join-information-p3">
            <b>1、{{$t("join.rg4")}}</b>
          </p>
          <p class="join-information-p3" v-html='$t("join.rg5")'>
          </p>
          <p class="join-information-p3">
            <b>2、{{$t("join.rg6")}}</b>
          </p>
          <!-- <p class="join-information-p3"> -->
            <a href="https://pan.baidu.com/s/1s2XN-ZQYOBFAIPfeUtarUg?pwd=q7vk" class="join-information-p3">{{$t("join.rg7")}}</a>
          <!-- </p> -->
          <p class="join-information-p3">
            <b>3、{{$t("join.rg8")}}</b>
          </p>
          <p class="join-information-p3">
           {{$t("join.rg9")}}
          </p>
        </div>
        <!-- 复试时间、地点 -->
        <div class="join-information">
          <p class="join-information-p1">{{ $t("join.se1") }}</p>
          <p class="join-information-p3">{{$t("join.se2")}}</p>
          <p class="join-information-p3">
            {{$t("join.se3")}}
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      type: 1,
      title: "中型音乐会",
    };
  },
  computed: {},
  created() {},
  mouthed() {},
  methods: {
    concert(val) {
      this.type = val.id;
      this.title = val.name;
    },
    handleSizeChange(val) {
      console.log(96, val);
    },
  },
};
</script>



<style lang="scss" scoped>
#app {
  background-color: #f2f6f7;
  img{
    max-width: 100%;
  }
}
.artTraining-title {
  height: 25px;
  overflow: hidden;
  .artTraining-beam1 {
    width: 20px;
    height: 1px;
    background-color: #b3b3b3;
  }
  .artTraining-beam2 {
    width: 4px;
    height: 25px;
    background-color: #b49a71;
  }
  .artTraining-xhz {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
    position: relative;
    height: 25px;
    .artTraining-xhz-p {
      line-height: 18px;
    }
    .artTraining-xhz-img {
      height: 10px;
      position: relative;
      top: -9px;
    }
  }
  .artTraining-beam3 {
    flex: 1;
    height: 1px;
    background-color: #b3b3b3;
    margin-left: 14px;
  }
}
.join-hz {
  padding: 13px 8px 13px 29px;
  width: 100%;
  box-sizing: border-box;
}
.join-hz-p1 {
  line-height: 18px;
}
.join-img {
  padding: 0 23px 0 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .join-img1 {
    width: 100%;
    position: relative;
    z-index: 11;
    vertical-align: top;
  }
  .join-img-hz {
    width: 151px;
    height: 98px;
    background-color: #b49a71;
    position: absolute;
    right: 16px;
    bottom: -7px;
    z-index: 10;
  }
}
.join-hz2 {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  .join-img3 {
    width: 111px;
    height: 178px;
    position: absolute;
    top: -100px;
    right: 0;
    z-index: 10;
  }
  .join-img4 {
    position: relative;
    z-index: 11;
  }
  .join-recruitment {
    position: relative;
    z-index: 11;
    width: 100%;
    background-color: #fff;
    border-radius: 20px 0 20px 0;
    padding: 40px 10px;
    box-sizing: border-box;
    .join-recruitment-hz {
      width: 98px;
      height: 30px;
      border-radius: 4px;
      background-color: #bd9658;
      box-shadow: 0px 4px 4px 0px rgba(189, 150, 88, 0.2);
    }
    .join-recruitment-hz::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #bd9658;
      margin-top: 30px;
    }
  }
}
.join-information {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 26px;
  .join-information-p1 {
    color: #835c46;
    font-size: 18px;
    font-weight: 400;
  }
  .join-information-p2 {
    color: #444444;
    font-size: 16px;
    font-weight: 400;
  }
  .join-information-p3 {
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 300;
  }
}
.join-information-span{
    color: #444444;
    font-size: 14px;
    font-weight: 400;
}
</style>

